@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    @apply font-body bg-background;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    @apply font-heading;
}